
  import { defineComponent, ref, computed, watch, reactive, getCurrentInstance } from "vue"
  import { segmentColumnsSeller } from '@/view_scripts/table_columns/segment_columns'
  import { Contract } from "@/models/contracts";
  import { Segment } from "@/models/segments";
  import { apiDataMorpher, extractRelationshipIds, modelTranslation, extractIncludedTypes, fetchRelated, localIndex } from "@/utils/apiDataHelper"
  import { resourceUrl } from "@/utils/dataExtractors"
  import { AttributesObject, ResourceObject, RelationshipsWithData, ResourceIdentifierObject } from '@/models/jsonapi'
  import { BvTableCtxObject } from "bootstrap-vue";
  import Tickets from "@/components/shared/Tickets.vue"
  import ContractKeyData from "@/components/shared/ContractKeyData.vue"
  import SchemaForm from "@/components/shared/form/SchemaForm.vue"
  import { CareStay } from "@/models/care_stays";
  import { segmentSchema, uiSegmentSchemaSeller as uiSegmentSchema } from "@/view_scripts/form_schemas/segment"
  import { contractSchema, uiContractSchema } from "@/view_scripts/form_schemas/contract"
  import FileMetaData from "@/components/shared/FileMetaData.vue"
  import { newTicketAction } from "@/view_scripts/title_action_new_ticket";
  import TicketEdit from "@/components/shared/TicketEdit.vue"
  import Tasks from "@/components/shared/Tasks.vue"
  import BkSuggestions from "@/components/shared/BKSuggestions.vue"
  import CareStayTable from "@/components/seller/CareStayTable.vue"
  import SuggestionSelect from "@/components/shared/SuggestionSelect.vue"
  import BkPresentMailEdit from "@/components/shared/BkPresentMailEdit.vue"
  import { confirm, prompt } from '@/utils/interactionModals'
  import { stringSort } from "@/utils/helper"
  import { provideRefreshData } from "@/utils/refreshData"
  import { fullName } from "@/utils/dataExtractors"

  interface Props {
    id: string
  }

  export default defineComponent({
    components: {
      Tickets,
      ContractKeyData,
      SchemaForm,
      FileMetaData,
      TicketEdit,
      Tasks,
      SuggestionSelect,
      BkPresentMailEdit,
      BkSuggestions,
      CareStayTable
    },
    props: { id: { type: String, required: true } },
    setup(props: Props) {
      const root = getCurrentInstance().proxy
      const contractResponse = computed(() => root.$store.state.contracts.elementResponse)
      const contract = computed<ResourceObject<string, Contract>>(() => root.$store.state.contracts.element)
      const suggestionIds = ref<string[]>([])
      const bkSelectModal = ref()
      const mailEditModal = ref()
      const mailData = reactive({mail: {}, suggestions: []})
      const careStays = ref<ResourceObject<string, CareStay>[]>([])
      const segments = ref<ResourceObject<string, Segment>[]>([])
      // const totalSegments = computed(() => root.$store.state.segments.pagination.totalItems)
      const totalSegments = ref<number>(0)
      watch(contract, (newValue) => {
        const segmentIds = extractRelationshipIds({ data: [newValue] }, 'segments')
        totalSegments.value = segmentIds.length
        suggestionIds.value = extractRelationshipIds({ data: [newValue] }, 'suggestions')
        const query = { 'filter[id]': segmentIds.join(), 'page[size]': segmentIds.length, include: 'seller_bill'}
        root.$store.dispatch('segments/load', query).then((r) => {
          apiDataMorpher(r.data,'sellerBill')
          const segs = r.data
          const cgiIds = segs.map((s)=>s.relationships?.care_giver_instance?.data?.id).join()
          const query = {'filter[id]': cgiIds, include: 'care_giver'}
          root.$store.dispatch('care_giver_instances/load', query).then((r) => {
            apiDataMorpher(r.data,'careGiver')
            segs.forEach((s) => {
              const cgi = r.data.find((cgi) => cgi.id === s.relationships?.care_giver_instance?.data?.id)
              s.attributes.careGiverInstance = cgi
            })
            segments.value = stringSort(segs, (s) => s.attributes.start, false)
          })
        })
      })
      // const segments = computed<ResourceObject<string, Segment>[]>(() => {
      //   const segments = contractResponse.value?.included?.filter(obj => obj.type === 'segments') ?? []
      //   const cgiIds = segments.map((s)=>s.attributes.careGiverInstanceId).join()
      //   const query = {'filter[id]': cgiIds, include: 'care_giver'}
      //   // const cgis = await root.$store.dispatch('care_giver_instances/load', query)
      //   // console.log({cgis})
      //   // .then((r) => {
      //   //   apiDataMorpher(r.data,'careGiver')
      //   //   segments.forEach((s) => {
      //   //     const cgi = r.data.find((cgi) => cgi.id === s.attributes.careGiverInstanceId)
      //   //     console.log({cgi})
      //   //     s.attributes.careGiverInstance = cgi
      //   //   })
      //   // })
      //   return stringSort(segments, (s) => s.attributes.start, false)
      // })
      // watch(segments, (newValue) => {
      //   const cgiIds = newValue.map((s)=>s.attributes.careGiverInstanceId).join()
      //   const query = {'filter[id]': cgiIds, include: 'care_giver'}
      //   root.$store.dispatch('care_giver_instances/load', query).then((r) => {
      //     apiDataMorpher(r.data,'careGiver')
      //     newValue.forEach((s) => {
      //       s.attributes.careGiverInstance = r.data.find((cgi) => cgi.id === s.attributes.careGiverInstanceId)
      //     })
      //   })
      // })
      // const careGiverInstances = computed(() => {
      //   const storeData = root.$store.state.care_giver_instances.data
      //   storeData.forEach((cgi) => {
      //     const segmentId = (cgi.relationships?.segments as RelationshipsWithData).data?.[0]?.id
      //     const segment = segments.value.find((s) => s.id === segmentId)
      //     if (segment?.attributes) segment.attributes.careGiverInstance = cgi
      //   })
      //   return storeData
      // })
      const ticketIds = computed<string[]>(() => contract.value ? extractRelationshipIds({ data: [contract.value] }, 'tickets') : [])
      const visorId = computed<string>(() => {
        const postingsResponse = root.$store.state.postings.response
        return postingsResponse.included?.[0]?.id
      })
      const showLoading = ref<Boolean>(false)
      const editSegmentModal = ref()
      const editContractModal = ref()
      const newTicketModal = ref()
      const newSuggestionModal = ref()
      const segmentToEdit = ref<ResourceObject<string, Segment>>()
      const contractToEdit = ref<ResourceObject<string, Contract>>()
      const contractProcessModal = ref()
      const contractProcessData = reactive<{[key: string]: any}>({
        reasons: [],
        customReason: '',
        selectedReason: null,
        end: '',
        displayEndInput: false,
      })

      const refreshData = () => {
        getCareStays().then(processCareStayResponse)
        return Promise.all([
          root.$store.dispatch('contracts/get', { id: props.id, queryParams: { include: 'household,file_meta_datas,agency' } }),
          root.$store.dispatch('tasks/load', { 'filter[taskable_id]': props.id, include: 'action_type' } )
        ])
      }

      const getCareStays = async (visorIds: string[]) => {
        const query = {
          'filter[contract_id]': props.id,
          'include': 'careGiverInstance.careGiver,visor.agency',
          'fields[care_givers]': 'firstName,lastName',
          'fields[agencies]': 'name',
          'page[size]': 200
        }
        return root.$store.dispatch('care_stays/load2', query)
      }

      const processCareStayResponse = (resp:any) => {
        const idx = localIndex(resp)
        const tableItems = resp.data.reduce((res: any[], cs: any) => {
          const cg = fetchRelated(cs, 'careGiverInstance.careGiver', idx)
          const agency = fetchRelated(cs, 'visor.agency', idx)
          cs.meta = {
            cGName: fullName(cg),
            cgiId: cs.relationships?.careGiverInstance?.data?.id,
            contractId: props.id,
            agencyName: agency?.attributes?.name
          }
          res.push(cs)
          return res
        },[])
        careStays.value = stringSort(tableItems, (s) => s.attributes.stage, false)
      }

      provideRefreshData(refreshData)

      const lastSegment = computed(() => {
        if (segments.value.length === 0) return null
        if (segments.value.length === 1) return segments.value[0]
        return segments.value.sort(sortSegmentsByStart)[0]
      })

      const sortSegmentsByStart = (a, b) => {
        return ((new Date(b.attributes.start) as any) - (new Date(a.attributes.start) as any))
      }

      const editSegment = (data: any) => {
        segmentToEdit.value = JSON.parse(JSON.stringify(data.item))
        editSegmentModal.value.show()
      }

      const columns = ref(segmentColumnsSeller({editSegment}))

      const saveSegment = async (bvModalEvent: any) => {
        bvModalEvent.preventDefault()
        if (segmentToEdit.value?.id === 'new') {
          await root.$store.dispatch('segments/create', segmentToEdit.value)
        } else if (segmentToEdit.value?.id) {
          await root.$store.dispatch('segments/edit', { id: segmentToEdit.value.id, body: segmentToEdit.value })
        }
        refreshData()
        root.$nextTick(() => editSegmentModal.value.hide())
      }

      const saveContract = async (bvModalEvent: any) => {
        bvModalEvent.preventDefault()
        await root.$store.dispatch('contracts/edit', { id: props.id, body: contractToEdit.value })
        refreshData()
        root.$nextTick(() => editContractModal.value.hide())
      }

      const updateFiles = async (data:any) => {
        await root.$store.dispatch('contracts/edit', { id: props.id, body: data })
        refreshData()
      }

      const submitTicket = () => {
        refreshData()
        newTicketModal.value.hide()
      }

      const getBkMailInfo = async (suggIds: string[]) => {
        showLoading.value = true
        await root.$store.dispatch('suggestions/get_presentation_mail_data', suggIds)
        mailData.mail = root.$store.state.suggestions.presentationMailData.data.mail
        mailData.suggestions = root.$store.state.suggestions.presentationMailData.data.suggestions
        bkSelectModal.value.hide()
        mailEditModal.value.show()
        showLoading.value = false
      }

      const titleActions = computed(() => {
        //if (!contract.value?.attributes) return []
        return [
          {
            title: 'Bearbeiten',
            icon: 'pencil-alt',
            tooltip: 'Haushalt bearbeiten',
            onClick: () => {
              const keys = Object.keys(contractSchema.properties.attributes.properties)
              const attrs:Contract = keys.reduce((res, k) => {
                res[k] = contract.value?.attributes?.[k]
                return res
              }, {} as Contract)
              contractToEdit.value = {attributes: attrs, type: 'contracts'}
              editContractModal.value.show()
            }
          },
          newTicketAction(newTicketModal),
          {
            title: 'Aktionen',
            children: [
              {
                title: 'Vertrag beenden',
                tooltip: 'Vertrag beenden. (Kunde hat gekündigt)',
                onClick: terminateContract
              },
              {
                title: 'Vertrag Abbrechen',
                tooltip: 'Vertrag nicht zu Stande gekommen. Wird entfernt',
                onClick: abortContract
              },
              // {
              //   title: 'Wechsel BK(s) vorschlagen',
              //   tooltip: 'BK Wechselvorschläge an den Kunden versenden',
              //   onClick: () => bkSelectModal.value.show()
              // },
            ]
          },
          // {
          //   categoryTitle: active ? 'Deaktivieren' : 'Aktivieren',
          //   title: active ? 'Deaktivieren' : 'Aktivieren',
          //   tooltip: active ? 'Deaktivieren' : 'Aktivieren',
          //   icon: active ? 'times' : 'check',
          //   onClick: () => contractsRepository.edit(props.id, { attributes: { active: !active }})
          // },
          // {
          //   categoryTitle: 'Archivieren',
          //   icon: 'archive',
          //   title: 'Archivieren',
          //   tooltip: 'Archivieren',
          //   onClick: () => alert('Archivieren')
          // },
        ]
      })

      const abortContract = async () => {
        if (segments.value.length > 1) {
          prompt(root, "Nur Verträge mit maximal einem Pflegeabschnitt können abgebrochen werden.")
        } else {
          contractProcessData.usecase = 'abort'
          contractProcessData.title = 'Vertrag abbrechen'
          contractProcessData.text = 'Der Vertrag wird gelöscht. Der Vorschlag wird entsprechend aktualisiert.'
          contractProcessData.reasons = []
          const listQuery = {'filter[sys_id]': 'contract_abort_reasons',include: 'sublists'}
          await root.$store.dispatch('lists/load', listQuery)
          contractProcessModal.value.show()
          contractProcessData.reasons = root.$store.state.lists.data[0].attributes.sublists.map((rr)=>{
            return {value: rr.id, text: rr.attributes?.name}
          }).concat({value: 'new', text: "Eigene Begründung angeben..."})
          contractProcessData.displayEndInput = false
          contractProcessData.reasons.unshift({value: null, text: 'Abbruchsgrund wählen'})
        }
      }

      const terminateContract = async () => {
        contractProcessData.usecase = 'terminate'
        contractProcessData.title = 'Vertrag beenden'
        contractProcessData.text = 'Der Vertrag wird archiviert. Falls der Kunde generell gekündigt hat, bitte auch den Lead resp. Haushalt absagen. Falls nur der Vertrag gekündigt worden ist, kann die Agentur dieses Vertrages zukünftige Stellenausschreibungen nicht mehr verfolgen. Um eine neue BK zu finden, muss die Stellenausschreibung reaktiviert werden. Dazu bitte die Ausschreibung aktualisieren (Anreisedatum) und reaktivieren.'
        contractProcessData.reasons = []
        contractProcessData.end = ''
        const listQuery = {'filter[sys_id]': 'contract_cancel_reasons',include: 'sublists'}
        await root.$store.dispatch('lists/load', listQuery)
        contractProcessData.reasons = root.$store.state.lists.data[0].attributes.sublists.map((rr)=>{
          return {value: rr.id, text: rr.attributes?.name}
        }).concat({value: 'new', text: "Eigene Begründung angeben..."})
        contractProcessData.reasons.unshift({value: null, text: 'Abbruchsgrund wählen'})
        contractProcessData.displayEndInput = !allSegmentsClosedTest()
        contractProcessModal.value.show()
      }

      const processContract = (usecase: string) => {
        let reason, data
        reason = contractProcessData.reasons.find((r) => r.value == contractProcessData.selectedReason)
        if (contractProcessData.selectedReason  === 'new') { reason = {text: contractProcessData.customReason} }
        if (usecase === 'abort') {
          data = {usecase, abort_reason: reason.text}
        } else if (usecase === 'terminate') {
          data = {usecase, termination_reason: reason.text, end: contractProcessData.end}
        }
        updateContract(data)
      }

      const updateContract = (data: any) => {
        showLoading.value = true
        root.$store.dispatch('contracts/edit', { id: props.id, body: data })
          .then(() => refreshData())
          .finally(() => {
            showLoading.value = false
          })
      }

      refreshData()

      const allSegmentsClosedTest = () => {
        return segments.value.filter((s) => !s.attributes?.end).length === 0
      }

      const sendMailData = async (mailData: any) => {
        try {
          showLoading.value = true
          const requestData = {usecase: 'deliver_bk_swap_mail', ...mailData}
          await root.$store.dispatch('contracts/edit', { id: props.id, body: requestData})
          mailEditModal.value.hide()
          const tmpIds = suggestionIds.value
          suggestionIds.value = []
          root.$nextTick(() => suggestionIds.value = tmpIds)
          // refreshData()
        } catch(e) {
          console.log('bkSwapMail error: ', e)
        } finally {
          showLoading.value = false
        }
      }

      return {
        showLoading,
        contract,
        segments,
        totalSegments,
        columns,
        titleActions,
        ticketIds,
        resourceUrl,
        modelTranslation,
        editSegmentModal,
        editContractModal,
        segmentSchema,
        uiSegmentSchema,
        contractSchema,
        uiContractSchema,
        saveSegment,
        saveContract,
        segmentToEdit,
        contractToEdit,
        updateFiles,
        refreshData,
        newTicketModal,
        submitTicket,
        lastSegment,
        contractProcessModal,
        contractProcessData,
        processContract,
        suggestionIds,
        bkSelectModal,
        getBkMailInfo,
        mailData,
        mailEditModal,
        sendMailData,
        careStays
      }
    }
  })
